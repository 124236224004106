import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import PageHeading from '../components/PageHeading';

export default () => (
	<Layout>
		<Seo title="Documentation" />
		<PageHeading
			title="Introduction"
			lead="Everything you need to get started with your BrightHR integration."
		/>
		<p>This content is a work in progress.</p>
		<p>[TODO] Intro to REST API</p>
		<p>[TODO] Environments &amp; Base URLs</p>
		<p>[TODO] Intro to Auth and link</p>
		<p>[TODO] Versioning Strategy</p>
		<p>[TODO] Creating Sandbox test accounts</p>
		<p>[TODO] Registering an application</p>
	</Layout>
);
